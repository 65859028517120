.item-lable {
  color: #d5d5d5;
}

.item-value {
  font-size: "18px";
  margin-top: "-15px";
  text-transform: "capitalize";
  color: "#000";
}
