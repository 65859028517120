.background-img {
  height: 102%;
  width: 100%;
  background-image: url("../../assets/images/backgroundImg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -7px;
  margin-left: 7px;
}
.login-card {
  width: 400px;
  margin-left: 30%;
  border: 0px;
  margin-top: 37px;
  height: 94vh;
}
@media (max-width: 1024px) {
  .background-login {
    background-image: url("../../assets/images/backgroundImg2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (max-width: 1024px) {
  .login-card {
    width: 290px;
    border: 0px;
    margin-top: 37px;
    height: 95vh;
  }
}