:where(.css-1jr9qlj).ant-menu-light .ant-menu-item-selected,
:where(.css-1jr9qlj).ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: #f5d3bd;
}

:where(.css-1jr9qlj).ant-menu-light .ant-menu-item-selected,
:where(.css-1jr9qlj).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #e28141;
}

@media (max-width: 1024px) {
  .show-on-desktop {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .show-on-mobile {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .Content {
    padding-left: 90px;
  }
}
.mobile_view_footer {
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: white;
  background: #e28141;
  height: 45px;
  z-index: 999;
  align-items: center;
  overflow: x;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-size: 15px;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
  border: 1px solid;
}
