:where(.css-1jr9qlj).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 38px;
  padding: 0 11px;
}
.css-13cymwt-control {
  height: 43px;
}
.tabs-label {
  font-size: 25px;
}

:where(.css-1jr9qlj).ant-tabs .ant-tabs-tab-btn {
  font-size: large;
}

:where(.css-1jr9qlj).ant-tabs .ant-tabs-tab-btn:hover {
  color: #e28141 !important;
}

:where(.css-1jr9qlj).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #e28141 !important;
  font-size: large;
}

:where(.css-1jr9qlj).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #e28141;
  pointer-events: none;
}

:where(.css-1jr9qlj).ant-pagination .ant-pagination-item-active a {
  color: #e28141;
}

:where(.css-1jr9qlj).ant-pagination .ant-pagination-item-active {
  border-color: #e28141;
}

:where(.css-1jr9qlj).ant-btn-primary {
  color: #fff;
  background-color: #e28141;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-1jr9qlj).ant-btn-default {
  color: #e28141;
  border-color: #e28141;
}
.phone.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 44px;
  width: 100%;
  outline: none;
}
/* .ant-tabs-nav-wrap {
  color: #e28141;
} */
input#price {
  height: 40px;
}
input#basic_price {
  height: 40px;
}

.ant-select-selector {
  height: 40px;
}
.ant-select-selector {
  height: 40px;
}
.ant-tabs .ant-tabs-tab-active span {
  color: #e28141;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #e28141;
}
.ant-select-show-search:where(
    .css-dev-only-do-not-override-12upa3x
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  height: 40px;
  padding: 3px;
}
:where(.css-dev-only-do-not-override-12upa3x).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  /* height: 32px; */
  padding: 0 11px;
}

