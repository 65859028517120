.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #e28141 !important;
}

.rbc-today {
  background-color: #f5d3bd !important;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #e28141;
}

:where(.css-1jr9qlj).ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-1jr9qlj).ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-1jr9qlj).ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: #fff;
  background: #e28141;
}

:where(.css-1jr9qlj).ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid #e28141;
}

:where(
    .css-1jr9qlj
  ).ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: #e28141;
}

:where(
    .css-1jr9qlj
  ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #e28141;
  background: #ffffff;
  border-color: #e28141;
}

@media (min-width: 600px) and (max-width: 900px) {
  .Calendar {
    width: 80vh;
    overflow-x: scroll;
  }
}
@media (max-width: 533px) {
  .tabs {
    width: 45vh;
  }
}
.table:where(.css-1jr9qlj).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  transition: background 0.2s, border-color 0.2s;
  border-bottom: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  padding: 6px;
}
