.App {
    font-family: sans-serif;
    text-align: center;
  }
.button{
    background-color: #E28141;
    color: #fff;
    border: 0px;
    height: 40px;
    border-radius: 10px;
}
.invoice {
  margin-bottom: 15px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  background: lightblue;
  padding: 0 17px;
}
.border {
  border: 1px solid #e5e5e5;
}
.invoice {
  margin-bottom: 15px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  background: lightblue;
   padding: 0 17px;
}
.border {
  border: 1px solid #e5e5e5;
}