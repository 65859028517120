.background-transparent {
  background-color: transparent !important;
}
.loading-div {
  width: 240px;
  height: 240px;
  background-color: #434343;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.please-wait {
  text-align: center;
  color: #fff;
}
@media (max-width: 600px) {
  .popover {
    display: none !important;
  }
}
